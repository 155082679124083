import { HubThemePartial } from "../../types";
import { merge } from "lodash/fp";
import xdiThemeBase from "./_base";

const xdiDemoTheme: HubThemePartial = merge(xdiThemeBase, {
  meta: {
    name: "XDI Climate Risk Hub - Demo",
  },
} as HubThemePartial);

export default xdiDemoTheme;
